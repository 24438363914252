import api from './api'

const getBasePath = () => {
  return window.localStorage.getItem("user") === 'csp' ? '/csp' : ''
}

export default {
    clickfind(data){
      return api.execute('post', `${getBasePath()}/clickfind`, data)
    },
}
